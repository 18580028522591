<template>
  <div>
    <div v-if="grantingAccess">
      <div class="w-full h-screen grid content-center justify-items-center">
        <img :src="require('@/assets/images/loader.gif')" class="w-1/3" />
        <p class="text-white text-xl font-bold">Granting access...</p>
      </div>
    </div>
    <div v-else>
      <div v-if="isDesktop">
        <DesktopLayout :desktopQR="desktopQR" />
      </div>
      <div class="bg-black register p-8 text-white" v-else>
        <div>
          <div>
            <div class="flex justify-center">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
                alt="Playda Logo"
                width="150"
                height="53"
              />
            </div>
            <div class="text-center font-ZuumeSemiBold text-2xl mt-2">
              Game demos
            </div>
            <div class="mt-6">
              <div>
                <p class="font-InterSemiBold uppercase">Email ID</p>
                <input
                  type="email"
                  name="email"
                  id="email"
                  v-model="email"
                  class="textInput"
                  placeholder="Preferred Email for login"
                />
              </div>
            </div>
          </div>
          <div class="w-5/6 absolute bottom-6">
            <div class="w-full grid mt-6">
              <div class="w-full">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/gamer/log_in_illustration.webp"
                  width="320"
                  height="229"
                  class="absolute bottom-40 transform -translate-x-1/2 left-1/2"
                />
              </div>
              <div>
                <button
                  @click.prevent="validateEmail()"
                  :class="[
                    'bg-nColorYellow rounded-3xl w-full h-14 text-nColorDarkGray border border-nColorDarkGray shadow-orange-right text-center uppercase text-3xl font-ZuumeSemiBold focus:outline-none',
                    { disabled: isValidateLoading },
                  ]"
                  :disabled="isValidateLoading"
                >
                  <span v-if="isValidateLoading" class="flex justify-center">
                    <svg
                      width="60"
                      height="15"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                  <span v-else> Login </span>
                </button>
              </div>
              <div class="w-full text-center pl-4">
                <div class="text-sm inline-block mt-2 h-4">
                  <div v-if="errorMsg" class="error text-red-600 rounded">
                    <p class="text-sm">{{ errorMsg }}</p>
                  </div>
                  <div v-if="successMsg" class="success text-green-700">
                    <p class="text-sm">
                      {{ successMsg }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2 flex justify-center">
              <div class="form-group">
                <label for="subscribe">
                  <input
                    type="checkbox"
                    id="subscribe"
                    name="subscribe"
                    v-model="subscribe"
                    checked
                  />
                  Subscribe to our newsletter
                </label>
              </div>
            </div>
            <div class="mt-4 flex justify-center">
              <a
                href="https://playda.io"
                target="_blank"
                class="font-InterSemiBold underline"
                >Don't have an account? Register</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import DesktopLayout from "@/components/elements/DesktopLayout.vue";

export default {
  name: "Login",
  components: { DesktopLayout },
  data() {
    return {
      email: null,
      subscribe: true,
      isValidateLoading: false,
      grantingAccess: false,
      isLoginLoading: false,
      emailSection: true,
      otpSection: false,
      resendDisabled: false,
      countdown: 60,
      otp: "",
      errorMsg: "",
      successMsg: "",
      errorMsgSubmit: "",
      successMsgSubmit: "",
      isDesktop: false,
      isMobilePortrait: false,
      isMobileLandscape: false,
      desktopQR:
        "https://playda.s3.ap-south-1.amazonaws.com/demo/general-qr-code.png",
    };
  },
  created() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    if (this.$route.params.user_identifier && this.$route.params.src) {
      this.grantingAccess = true;
      ApiService.post(apiResource.validateUserIdentifier, {
        user_identifier: this.$route.params.user_identifier,
        src: this.$route.params.src,
      })
        .then((data) => {
          if (this.isDesktop) {
            this.desktopQR = data.data.data.user.payload.desktop_qr;
            this.grantingAccess = false;
          } else {
            this.$store.dispatch("validateOTP", data);
            this.goToCatalog();
          }
        })
        .catch(() => {
          this.grantingAccess = false;
        });
    }
  },
  mounted() {},
  computed: {},
  methods: {
    updateScreenSize() {
      this.isDesktop = window.matchMedia("(min-width: 768px)").matches;
      if (!this.isDesktop) {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const orientation = window.matchMedia(
          "(orientation: landscape)"
        ).matches;
        this.isMobilePortrait = isMobile && !orientation;
        this.isMobileLandscape = isMobile && orientation;
      }
    },
    validateEmail() {
      this.isValidateLoading = true;
      this.errorMsg = "";
      if (this.email) {
        ApiService.post(apiResource.userLogin, {
          email: this.email,
          subscribe: this.subscribe,
        })
          .then((data) => {
            this.isValidateLoading = false;
            this.$store.dispatch("validateOTP", data);
            this.goToCatalog();
          })
          .catch((error) => {
            this.isValidateLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isValidateLoading = false;
        this.errorMsg = "Please enter a valid email id";
      }
    },
    goToCatalog() {
      this.$router.replace({
        name: "Catalog",
      });
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
};
</script>

<style scoped lang="postcss">
.register {
  background-color: #000000;
  background-image: none;
  height: 100dvh;
}
.textInput {
  @apply w-full text-white pt-2 pb-3 border-b border-nColorLightGray focus:outline-none focus:border-gray-500 bg-transparent;
}
.radio-group {
  display: flex;
}

.radio-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Style the radio input to be hidden */
input[type="radio"] {
  display: none;
}
.selected {
  @apply border-nColorOrange text-nColorOrange;
}
.otpText {
  @apply bg-transparent rounded-lg font-ZuumeSemiBold text-white border border-nColorLightGray text-5xl focus:outline-none focus:bg-nColorOrange text-center w-16 h-16 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
