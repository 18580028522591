<template>
  <div class="wrapper">
    <div class="grid justify-items-center content-center">
      <div class="flex gap-2 items-end">
        <div
          class="bg-dColorBG border-2 border-color rounded-3xl p-6 left-section"
        >
          <p class="text-nColorYellow">Games Catalog:</p>
          <p class="text-white font-bold">16 Diverse Games</p>
        </div>
        <div class="middle-section px-6 relative">
          <div class="illustration1-position">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/demo/demo_illustration1.webp"
              alt="Demo Illustration1"
              width="220"
              height="436"
            />
          </div>
          <div class="illustration2-position">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/demo/demo_illustration2.webp"
              alt="Demo Illustration2"
              width="220"
              height="436"
            />
          </div>
          <div
            class="grid bg-dColorBG justify-items-center gap-4 border-2 border-color rounded-3xl px-6 py-4"
          >
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
              alt="Playda Logo"
              width="180"
              height="78"
            />
            <div class="text-2xl text-nColorYellow font-bold text-center">
              This is a mobile browser only experience
            </div>
            <div class="text-base text-white text-center">
              Scan this QR to play our games on your mobile phones
            </div>
            <div class="bg-black rounded-3xl border-4 border-nColorYellow">
              <img
                :src="desktopQR"
                alt="Playda Demo QR Code"
                width="250"
                height="250"
              />
            </div>
          </div>
        </div>
        <div
          class="bg-dColorBG border-2 border-color rounded-3xl p-6 right-section"
        >
          <p class="text-nColorYellow">Games Plays:</p>
          <p class="text-white font-bold">Over 52k+</p>
        </div>
      </div>
      <div class="flex gap-6 items-start mt-8">
        <div
          class="bg-dColorBG border-2 border-color rounded-3xl p-6 bottom-section"
        >
          <p class="text-nColorYellow">Stickiness:</p>
          <p class="text-white font-bold">
            85% user engagement and retention rate
          </p>
        </div>
        <div
          class="bg-dColorBG border-2 border-color rounded-3xl p-6 bottom-section"
        >
          <p class="text-nColorYellow">Game rating:</p>
          <p class="text-white font-bold">
            Average rating of 4.7/5 stars by players
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    desktopQR: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: { ...mapGetters(["isPageLoading"]) },
  methods: {},
  mounted() {},
};
</script>

<style lang="postcss">
.wrapper {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures the content takes full viewport height */
}
.border-color {
  border: 1px solid #533c89;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.left-section,
.right-section {
  width: 200px;
}
.middle-section {
  width: 480px;
}
.bottom-section {
  width: 250px;
}
.illustration1-position {
  @apply -top-4 absolute;
  left: -12.2rem;
}
.illustration2-position {
  @apply top-12 absolute;
  right: -12.2rem;
}
</style>
